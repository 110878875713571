<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        height="50"
      >
        <v-toolbar-title>Service Calls</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-col cols="2">
          <v-btn
            icon
            class="ml-10"
            right
            @click="setFilter"
          >
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>
        <v-btn
          color="accent"
          small
          to="/service-call/create"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <!-- start of defaults list -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="serviceCalls"
            >
              <template v-slot:item.DocNum="{ item }">
                <v-btn
                  color="accent"
                  text
                  :to="`/service-call/edit/${item.id}`"
                >{{ item.DocNum }}</v-btn>
              </template>

              <template v-slot:item.syncStatus="{ item }">
                <v-btn
                  @click="openerrorDocDialog(item)"
                  color="red"
                  icon
                  v-show="item.withErrors == true"
                >
                  <v-icon>mdi-alert</v-icon>
                </v-btn>
                <v-btn
                  color="green"
                  icon
                  v-show="item.withErrors == false"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>

                <v-btn
                  color="grey"
                  icon
                  v-show="item.withErrors == 'upload'"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template>

              <!-- start of posting date template -->
              <template v-slot:item.created_at="{ item }">{{ item.created_at | moment("Do MMM YYYY")}}</template>
              <!-- end of posting date template -->

            </v-data-table>
          </v-col>
        </v-row>

        <!-- end of defaults table list -->
      </v-card-text>
    </v-card>

    <!-- category quick add dialog -->
    <v-dialog
      v-model="errorDocDialog"
      max-width="650px"
    >
      <v-card>
        <v-toolbar
          dense
          color="red"
          dark
        >
          <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.native="errorDocDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                This document was not sync to SAP due to below following error:

              </v-col>
              <v-col cols="12">

                <span>
                  {{this.ErrorMessage}}
                </span>

              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn
                    color="green"
                    dark
                    @click="errorDocDialog = false"
                  >Close</v-btn>
                </v-col>
              </v-row>
            </v-row>

          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->

  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    search: "",
    record: {},
    ErrorMessage: null,
    serviceCalls: [],
    errorDocDialog: false,
    headers: [
      { text: "", value: "syncStatus" },
      { text: "Doc No.", value: "DocNum" },
      { text: "SAP#", value: "ExtRefDocNum" },
      { text: "CardCode", value: "customer" },
      { text: "Customer Name", value: "custmrName" },
      { text: "Created On", value: "created_at" },
    ],
  }),
  methods: {
    setFilter() {
      this.getData();
    },
    openerrorDocDialog(item) {
      this.ErrorMessage = item.ErrorMessage;
      this.errorDocDialog = true;
    },
    getData() {
      const self = this;
      this.$store
        .dispatch("get", `/service-calls`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.serviceCalls = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>